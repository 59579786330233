
footer
    box-sizing: border-box
    font-size: 20px
    border: none
    color: #fff
    background-color: #000
    .Link
        font-size: 15px
        color: #fff
        text-decoration: none
        .logo
            max-width: 310px

@media (max-width: 820px)
    footer
        min-width: 100vw
        height: 200px
        font-size: 40px
        gap: 5px
        text-align: center
        .Link
            font-size: 30px
        .logo
            max-width: 310px
@media (max-width: 420px)
    footer
        max-width: 100vw
        padding: 5px 0
        height: 50px
        font-size: 15px
        justify-content: center
        gap: 10%
        div
            max-width: 180px

        .Link
            font-size: 15px
            max-width: 150px
        .logo
            margin: 0
            margin-left: 5px
