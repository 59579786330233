.computer
  h1
    color: #fff
    text-align: center
    font-size: 60px
    font-weight: 600
  p, h2
    color: #fff
    text-align: center
    margin-top: 35px
    font-size: 29px
    font-weight: 400
  h2
    margin-top: 20px
    font-weight: 600

  .important
    margin-top: 30px
    text-align: center
    p
      margin-top: 10px
      color: #ffffff
      font-size: 20px
      span
        font-size: 30px
        font-weight: 600
  .coputerLink
    p
      margin-top: 10px
      text-align: center
      font-size: 20px
    .btn
      width: 320px
      background-color: #4CAF50
      color: white
      padding: 14px 20px
      margin: 0 auto
      margin-top: 35px
      border: none
      border-radius: 4px
      cursor: pointer
      font-size: 18px
      transition: opacity 500ms ease
      &:hover
        opacity: 0.5

        position: relative
  .computerForm
    width: 500px
    margin: 0 auto
    margin-top: 35px
    display: flex
    flex-direction: column
    align-items: center
    padding: 25px
    border: 1px solid #ccc
    border-radius: 10px 10px 0 0
    background-color: #ffff
    .eror
      color: red
      font-size: 15px
      font-weight: 400
    & label
      font-size: 20px
      width: 100%
      color: #000000
      margin-bottom: 10px
    span
      font-size: 15px
      font-weight: 400

    & input, textarea
      width: 100%
      padding: 12px 20px
      margin: 8px 0
      box-sizing: border-box
      border: 1px solid #ccc
      border-radius: 4px
      font-size: 16px
      overflow: auto
    textarea
      overflow: auto
      resize: none
      height: 100px

    & button
      width: 100%
      background-color: #4CAF50
      color: white
      padding: 14px 20px
      margin: 8px 0
      border: none
      border-radius: 4px
      cursor: pointer
      font-size: 18px
      transition: opacity 500ms ease
      &:hover
        opacity: 0.5

@media (max-width: 820px)
  .computer
    margin: 0

    h1
      font-size: 95px
    p, h2
      font-size: 60px
    h2
      margin-top: 20px
      font-weight: 600

    .computerForm
      width: 700px
      margin-top: 50px
      padding: 25px
      .eror
        font-size: 25px
      & label
        max-width: 800px
        font-size: 40px
      span
        font-size: 25px
      & input
        width: 100%
        font-size: 32px
        padding: 25px 20px
      & textarea
        height: 250px
        font-size: 40px
      & button
        padding: 32px 20px
        margin: 8px 0
        font-size: 60px
        &:hover
          opacity: 1

    .important
      p
        font-size: 63px
        span
          font-size: 90px
          font-weight: 600
    .coputerLink
      .btn
        margin: 0 auto
        width: 700px
        padding: 20px 20px
        margin-top: 35px
        font-size: 46px
        text-align: center
        font-weight: 500
        &:hover
          opacity: 1
      p
        font-size: 50px

@media (max-width: 420px)
  .computer
    padding: 15px
    h1
      font-size: 45px
    p, h2
      font-size: 25px
    h2
      margin-top: 20px

    .computerForm
      width: 300px
      padding: 15px
      .eror
        font-size: 15px
      & label
        font-size: 25px
      span
        font-size: 17px
      & input
        font-size: 14px
        padding: 15px 10px
      & textarea
        height: 200px
        font-size: 20px
      & button
        padding: 10px 0
        font-size: 27px

    .important
      p
        font-size: 40px
        span
          font-size: 50px
    .coputerLink
      .btn
        width: 307px
        font-size: 19px
        padding: 15px
      p
        font-size: 30px
