.buy-used-phones-page
  font-family: 'SF Pro Text', 'Helvetica Neue', Arial, sans-serif
  color: #333
  max-width: 900px
  margin: auto
  padding: 20px
  margin-top: 90px

  h1, h2
    color: #000
    text-align: center
    text-transform: uppercase

  h1

    font-size: 2.2em
    margin-bottom: 20px
    background-size: 200% auto

    background: linear-gradient(90deg, red, #0072ff, green, blue)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent

  h2
    font-size: 1.8em
    margin-top: 40px
    margin-bottom: 20px

  .introduction, .how-it-works, .advantages, .customer-reviews
    margin-bottom: 40px
  .how-it-works
    text-align: center

    .steps
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      gap: 20px
    .step
      flex: 1
      padding: 20px
      border: 1px solid #ccc
      border-radius: 5px
      background-color: #f9f9f9
      min-width: calc(33.333% - 20px)

      &-icon
        font-size: 2rem
        margin-bottom: 10px

      p, ol, ul
        font-size: 1em
        line-height: 1.6

      ol, ul
        padding-left: 40px

  .cta
    text-align: center

  a.whatsapp-button

    display: inline-block
    background-color: #34b7f1
    color: white
    padding: 15px 30px
    border-radius: 4px
    font-size: 1.1em
    text-decoration: none
    transition: background-color 0.3s

  a.whatsapp-button:hover
    background-color: #007BFF
  .seo-article
    margin-bottom: 40px

  h2, h3
    color: #ff5300
    text-align: center

  h2
    font-size: 1.8em
    margin-top: 40px
    margin-bottom: 20px

  h3
    font-size: 1.6em
    margin-top: 30px
    margin-bottom: 15px
    text-transform: uppercase

  p, ul
    font-size: 1em
    line-height: 1.6
    color: #333

  ul
    padding-left: 40px

  li
    margin-bottom: 10px

  strong
    color: #000

@media (max-width: 821px)
  .buy-used-phones-page
    margin-top: 10px
    .how-it-works
      .step
        min-width: calc(100% - 40px)
