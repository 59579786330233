.poap_container
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 80%
  height: 30%
  background-color: #00000080
  border-radius: 10px
  box-shadow: 0px 0px 10px #ccc
  display: flex
  align-items: center
  justify-content: center

.poap_image
  width: 50px
  height: 50px
  margin-right: 10px

.poap_title
  font-size: 20px
  font-weight: bold
