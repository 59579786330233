/* WhatsAppForm.module.css */
.formContainer
  display: flex
  flex-direction: column
  width: 90vw
  max-width: 500px
  min-height: 100vh
  margin: 0 auto
  margin-top: 115px
  margin-bottom: 20px
  padding: 20px
  border: 1px solid #ccc
  border-radius: 8px
  background: #f9f9f9
  h1
    font-size: 20px
    text-align: center

  p
    font-size: 16px
    font-weight: 500
    margin-top: 10px
    margin-block: 20px
    text-align: center
  select
    text-align: center
    padding: 5px
  textarea
    resize: none
    height: 100px

.formField
  width: 100%
  display: flex
  flex-direction: column
  margin-bottom: 15px
  padding: 10px
  gap: 5px
  box-sizing: border-box

.inputField
  width: 100%
  box-sizing: border-box
  padding: 10px
  border: 1px solid #ccc
  border-radius: 4px

.selectField
  width: 100%
  padding: 10px
  border-radius: 4px
  border: 1px solid #ccc

.textAreaField
  width: 100%
  height: 100px
  padding: 10px
  border: 1px solid #ccc
  border-radius: 4px

.submitButton
  padding: 10px 20px
  border: none
  border-radius: 4px
  background-color: #007bff
  color: white
  cursor: pointer
  transition: background-color 0.2s

  &:hover
    background-color: #0056b3

.checkboxLabel
  display: flex
  align-items: center
  margin-bottom: 10px

.checkboxInput
  margin-right: 5px

.checkbox
  display: flex
  flex-direction: column
  gap: 5px
  div
    display: flex
    flex-direction: row
    gap: 10px
    p
      font-size: 16px
      font-weight: 500

@media (max-width: 768px)
  .formContainer
    margin-top: 20px
    padding: 10px

    h1
      font-size: 18px

    p
      font-size: 14px

    .formField
      padding: 5px

    .inputField,
    .selectField,
    .textAreaField,
    .submitButton
      font-size: 14px
      padding: 8px

    .checkboxLabel p
      font-size: 14px
