
.link
  text-decoration: none
  color: inherit

.header
  min-width: 1260px
  display: flex
  align-items: center
  justify-content: space-between
  background-color: #e5f1ed
  padding: 10px
  position: fixed
  top: 0
  z-index: 10

  .logo

    .link
      img
        width: 50px

  .nav
    float: right
    li
      font-size: 20px
      display: inline
      margin-left: 25px
      cursor: pointer
      transition: all 0.5s ease
      font-weight: 500
      &:hover
        opacity: 0.5

  .registerWrap
    display: flex
  .login, .register
    font-size: 20px
    padding: 10px
    border: 1px solid white
    background-color: #1565c0
    color: #fff
    font-weight: 400
    transition: all 0.3s ease
    &:hover
      opacity: 0.5
  .logout
    display: flex
    .isLogin
      margin-left: 15px

.burger
  width: 55px
  height: 55px
  display: flex
  flex-direction: column
  justify-content: space-between
  cursor: pointer
  display: none
  z-index: 100

  span
    width: 55px
    height: 7px
    margin-bottom: 10px
    background-color: #1565c0
    display: block

.burger.burgerClose
  position: relative
  span:first-of-type
    display: none

  span:nth-of-type(2)
    transform: rotate(45deg)
    transition: all 0.5s

  span:nth-of-type(3)
    transform: rotate(-45deg)
    transition: all 0.5s

  span
    margin: 0
    background-color: red
    position: absolute
    top: 50%

@media (max-width: 820px)
  .header
    position: static
    margin: 0
    min-width: 800px
    max-width: 820px
    display: grid
    grid-template-columns: repeat(2, 1fr) 2fr
    grid-template-rows: 1fr
    grid-column-gap: 30px
    grid-row-gap: 0px

    .logo
      width: 200px
      li
        font-size: 30px
    .burger.burgerClose
      height: 70px
      margin-left: 30px
    .burger
      margin-left: 30px
      display: block
      width: 70px
      height: 40px
      span
        width: 100%

    .nav
      display: none
      max-height: 0
      font-size: 0
      overflow: hidden
      transition: .5s all
    .nav.navOpen
      margin-top: 131px
      display: block
      width: 100%
      max-height: 800px
      position: absolute
      top: 0
      left: 0
      background-color: #e5f1ed
      z-index: 100
      ul
        margin-top: 50px
        display: flex
        flex-direction: column
        align-items: center
        .link
          margin-bottom: 80px
          li
            margin: 0
            font-size: 60px
            font-weight: 500

    .registerWrap
      justify-content: flex-end
      .login, .register
        margin: 0
        padding: 20px 10px
        font-size: 35px
    .logout
      .login
        font-size: 34px
        padding: 15px
      .isLogin
        font-size: 30px

@media (max-width: 420px)
  .header
    margin: 0
    min-width: 350px
    max-width: 100vw
    padding: 10px 0
    grid-column-gap: 1px

    .logo
      width: 100px
      margin-left: 20px
      li
        font-size: 15px
    .burger.burgerClose
      margin-left: 30px
    .burger
      margin-left: 15px
      width: 40px
      height: 40px
      span
        width: 40px
    .nav.navOpen
      width: 100vw
      // margin-top: 89px
      ul
        padding: 0
        .link
          margin-bottom: 80px

          li
            margin: 0
            font-size: 25px
            font-weight: 500

    .registerWrap
      justify-content: flex-start
      width: 160px
      margin-right: 15px
      .login, .register
        padding: 15px 5px
        font-size: 15px
    .logout
      .login
        margin: 0
        padding: 15px 5px
        font-size: 15px
      .isLogin
        font-size: 13px
