.popup
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    z-index: 100
    


.popupContent
    position: relative
    display: inline-flex
    flex-direction: column
    gap: 10px
    background-color: #fff
    padding: 20px
    padding-top: 60px
    max-width: 500px
    width: 90vw
    text-align: center
    text-transform: uppercase
    

    h3
        padding: 5px
        border-radius: 5px
        background: #1565c0
        margin-bottom: 5px
        color: #fff
        
    p
        font-weight: 400
        font-size: 15px
        margin-bottom: 15px
        text-transform: none
        
.closeBtn
    position: absolute
    top: 5px
    right: 10px
    width: 70px !important
    height: 40px
@media (max-width: 821px)
    .popupContent

        p
            font-size: 1.1rem
