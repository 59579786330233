.root
  width: 400px
  padding: 50px
  border: 1px solid #dedede
  margin: 50px auto
.wrapper
  margin-top: 100px
  margin-bottom: 250px
.field
  margin-bottom: 20px !important

.title
  text-align: center !important
  font-weight: bold !important
  margin-bottom: 30px !important
.resetPassword
  font-size: 15px
  margin-top: 20px
  font-weight: 300

@media ( max-width: 820px )
  .wrapper
    margin-bottom: 600px
    .root
      width: 700px
    form
      margin: 0 auto
      width: 600px
      height: 600px
      label
        font-size: 35px
      p
        font-size: 35px
      .title
        font-size: 70px
      input
        font-size: 50px
      button
        width: 600px
        font-size: 45px

@media ( max-width: 420px )
  .wrapper
    margin: 0
    margin-top: 80px
    margin-bottom: 180px
    .root
      padding: 0
      margin: 0 auto
      width: 370px
      height: 450px
    form
      width: 90%
      label
        font-size: 30px
      p
        font-size: 20px
      .title
        margin-top: 50px
        font-size: 35px
      input
        font-size: 30px
      button
        width: 100%
        font-size: 35px
